import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ClientPlatformService } from '../../client-platform/client-platform.service';
import { DialogSetupComponent } from '../../client-platform/common-components/dialog-setup/dialog-setup.component';
import { MappingIcons } from './mapping-icons';
import { MappingDrillDownDialog } from './mapping-drill-down/mapping-drill-down.dialog';
import { MappingAddAttributeDialog } from './mapping-add-attribute/mapping-add-attribute.dialog';

@Component({
  selector: 'app-mapping-setup',
  templateUrl: './mapping-setup.component.html',
  styleUrls: ['./mapping-setup.component.css'],
})
export class MappingSetupComponent implements OnInit {
  @Input() sourceFields: any;
  @Input() appFields: any;
  @Input() systemFields: any;
  @Input() mappingArr: any = [];
  @Input() preDefinedMapping?: any;
  @Input() boxConn: any; //DEPRECATED USE appInfo & sourceInfo instead
  @Input() appInfo: any;
  @Input() sourceInfo: any;
  @Input() serviceType?: string = "export"; //by default it is export, can be import, view etc.
  @Input() displayOption: any;
  @Output() mappingChange = new EventEmitter<any>();

  isEditEnabled: boolean = false;
  isMappingValid: boolean = true;
  mappingToggle: boolean = true;
  allMapping: any = [];
  autoMappedArr: any = [];
  itemsMappingArr: any = {};
  editMapFieldIndex = null;
  fieldMap: any;

  appFieldIconMap: any = MappingIcons.appFieldIconMap;
  sourceFieldIconMap: any = {};
  objectAttributes: any = [];
  filteredObjectAttributes: Observable<any[]>;
  filteredSourceAttributes: Observable<any[]>;
  filteredReferenceAttributes: Observable<any[]>;

  //form control for mapping fields
  selectedAppField = new UntypedFormControl();
  selectedMappingType = new UntypedFormControl('sourceField'); //initialize with source field as mapping type.
  selectedSourceField = new UntypedFormControl();
  templatedValue = new UntypedFormControl('');

  referenceFields: any = [];
  selectedReferenceField = new UntypedFormControl('');

  isSmallScreen: boolean;

  defaultConditionMap: any = {
    defaultField: "",
    defaultType :"text",
    conditionMap: {
      conditionSets: {
        conditions : [],
        resultField : "",
        resultType : "text"
      }
    }
  };

  conditionMap:any = this.defaultConditionMap;
  loadedAttributes: any = [];

  get isSupportsCustomAttribute() {
    return this.appInfo?.object?.supports?.includes('customattribute')
  }

  get sourceFieldValue() {
    return this.selectedMappingType.value == 'templateField' ?
    this.templatedValue.value : (this.selectedAppField.value.dataType == 'array' ? this.itemsMappingArr[this.selectedAppFieldId] : this.selectedSourceField.value);
  }

  get selectedAppFieldId() {
    return this.selectedAppField.value.__id;
  }

  get isLineItemMapping() {
    const appFieldValue = this.selectedAppField?.value;
    return appFieldValue?.dataType === 'array' && appFieldValue?.semanticType === 'lineItem';
  }

  constructor(
    public dialog: MatDialog,
    private breakPointObserver: BreakpointObserver,
    public clientPlatformService: ClientPlatformService
  ) {
    this.isSmallScreen = this.breakPointObserver.isMatched(Breakpoints.XSmall);
  }

  async ngOnInit() {
    console.log('service type', this.serviceType)
    //pick the field data type either from client service or from mapping icons.
    if(this.clientPlatformService.sourceFieldIconMap)
      this.sourceFieldIconMap = this.clientPlatformService.sourceFieldIconMap;
    else
      this.sourceFieldIconMap = MappingIcons.appFieldIconMap;

    if(this.mappingArr.length)
      this.allMapping = this.mappingArr;

    if(!this.sourceFields?.length)
       this.clientPlatformService.openErrorSnackBar('Source fields are missing, please provide all the required form inputs in the previous step!')

    //check whether the client is passing app fields as an input, if true, use that else get it from client service.
    if(this.appFields && this.appFields.length) {
      this.objectAttributes = this.appFields.filter((attr) => !(attr.writable == false));
      this.loadedAttributes = [];
      let appFields = JSON.parse(JSON.stringify(this.appFields))
      appFields.forEach(element => {
        const cachedEle = JSON.parse(JSON.stringify(element));
        element.__id = `old.${element.__id}`;
        element.name = `${element.name}`

        //If there are mandatory fields then add it to mapping list without source field.
        if (element.mandatory) {
          const mapObj = {
            appField: cachedEle,
            sourceField: null,
            mappingType: "sourceField"
          }
          const existingMapObj = this.allMapping.find((currMapObj: any) => currMapObj.appField.__id == cachedEle.__id);
          if (cachedEle.dataType == "array" && cachedEle.semanticType == "lineItem") {
            if (!this.itemsMappingArr[cachedEle.__id]) {
              this.itemsMappingArr[cachedEle.__id] = existingMapObj?.sourceField || [[]]
            }
            mapObj.sourceField = this.itemsMappingArr[cachedEle.__id];
          }

          if (!existingMapObj) {
            this.allMapping.push(mapObj);
            this.mappingChange.emit(this.allMapping);
          }
        }

        this.loadedAttributes.push(element)
      });
    }

    if (this.sourceFields?.length) {
      this.sourceFields.forEach(field => {
        if (field.dataType === "array" && field.semanticType === "lineItem") {
          field.semanticOptions.fields.list.forEach(lineField => {
            let lineItemFieldObj = JSON.parse(JSON.stringify(field.semanticOptions.fields[lineField]));
            lineItemFieldObj.__id = `\${${field.__id}.\$[__AW_LINE_index].${lineItemFieldObj.__id}}`;
            lineItemFieldObj.name = `${field.name} # ${lineItemFieldObj.name}`;
            if (!this.sourceFields.find((sField) => sField.__id == lineItemFieldObj.__id)) {
              this.sourceFields.push(lineItemFieldObj);
            }
          });
        }
      })
    }


    console.log("loadedAttributes-->", this.loadedAttributes)

    this.constructFieldMap();
    this.getFilteredObjectAttributes();
    this.getFilteredSourceAttributes();
    this.setPredefinedMapping();
    /* Auto mapping */
    this.checkForAutoMapping();
  }

  setPredefinedMapping() {
    if (this.preDefinedMapping) {
      const preDefinedMappingArr = [];
      Object.keys(this.preDefinedMapping).forEach((key) => {
        const appField = this.objectAttributes.find((field) => field.__id === key);
        const sourceField = this.sourceFields.find((field) => this.preDefinedMapping[key].__id === field.__id);
        const mapObj = { appField, sourceField, mappingType: "sourceField" };
        const isMappingExist = this.allMapping.some((mObj) => this.formatField(mObj.appField.name) == this.formatField(appField.name));
        if (isMappingExist) {
          this.handlePredefinedMapping(appField, mapObj);
        } else {
          preDefinedMappingArr.push(mapObj);
        } 
      })
      this.allMapping = this.allMapping.concat(preDefinedMappingArr);
      this.mappingChange.emit(this.allMapping);
    }
  }

  formatField(field: any) {
    return field.replace(/[\-_ ?*]/g, '').toLowerCase();
  }

  constructFieldMap(){
    let fieldObj:any = {};
    let list = [];

    if(this.appFields?.length > 0) {
      list.push('appFields');
      let fieldObjAppFields = this.getFieldMapObject('appFields','APP FIELDS',this.objectAttributes)
      fieldObj[fieldObjAppFields.id] = fieldObjAppFields
    }
    if(this.sourceFields?.length > 0) {
      list.push('sourceFields');
      let fieldObjsourceFields = this.getFieldMapObject('sourceFields','SOURCE FIELDS',this.sourceFields)
      fieldObj[fieldObjsourceFields.id] = fieldObjsourceFields
    }
    if(this.systemFields?.length > 0) {
      list.push('systemFields');
      var fieldObjsystemFields = this.getFieldMapObject('systemFields','SYSTEM FIELDS',this.systemFields)
      fieldObj[fieldObjsystemFields.id] = fieldObjsystemFields
    }
    fieldObj.list = list;
    this.fieldMap = fieldObj;
    console.log("FIELD MAP PREPARED : ",this.fieldMap)
  }

  getFieldMapObject(_id:string, displayName:string, fields:any, option?:any){
    var obj = {
      id : _id,
      displayName : displayName,
      fields : fields,
      options : option ? option : {}
    }
    return obj
  }

  async ngOnChanges(changes: SimpleChanges) {
    // console.log("changes in action", changes)

    if(!(changes?.appFields?.firstChange) && changes?.appFields?.currentValue){
      this.appFields = changes?.appFields?.currentValue;
      this.objectAttributes = this.appFields.filter((attr) => !(attr.writable == false));
    }

    if(!(changes?.sourceFields?.firstChange) && changes?.sourceFields?.currentValue) {
      this.sourceFields = changes?.sourceFields?.currentValue;
    }

    if(!(changes?.boxConn?.firstChange) && changes?.boxConn?.currentValue) {
      this.boxConn = changes?.boxConn?.currentValue;
    }

    if(!(changes?.mappingArr?.firstChange) && changes?.mappingArr?.currentValue) {
      this.mappingArr = changes?.mappingArr?.currentValue;
      if(!this.mappingArr.length) this.allMapping = [];
    }

  }

  radioChange(e){
    if(e.value == "conditional"){
      if(!this.conditionMap.conditionSets) this.conditionMap.conditionSets = [];
      if(!this.conditionMap.defaultField) this.conditionMap.defaultField = "";
      if(!this.conditionMap.defaultType) this.conditionMap.defaultType = "text";
      if(this.conditionMap.conditionSets.length == 0 ) {
        this.conditionMap.conditionSets.push({
          conditions : [],
          resultField : "",
          resultType : "text"
        })
      }
    }
    console.log("this.conditionMap", this.conditionMap)
  }

  openAutoMappingDialog(): void {
    const dialogRef = this.dialog.open(DialogSetupComponent, {
      width: '250px',
      data: {
        actionType: 'prompt',
        title: 'Intelligent Mapping',
        message: `${this.autoMappedArr.length} ${this.autoMappedArr.length == 1 ? 'field' : 'fields'} can be automatically mapped. Proceed?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed ', result);
      this.clientPlatformService.isAutoMapping = true;
      if (!result) this.autoMappedArr = [];
      else {
        this.allMapping = this.allMapping.concat(this.autoMappedArr);
        this.mappingChange.emit(this.allMapping);
        console.log('autoMapping', this.autoMappedArr);
      }
    });
  }

  async editMappingField(mapObj: any, index: string) {
    console.log('MAP OBJ', mapObj);
    console.log('INDEX', index);

    this.editMapFieldIndex = index;
    this.conditionMap = mapObj?.conditional || this.defaultConditionMap;

    if(mapObj.appField.dataType == 'array') {
      if (mapObj.sourceField) {
        this.itemsMappingArr[mapObj.appField.__id] = mapObj.sourceField;
      } else {
        this.addItem();
      }
    } else if (mapObj.mappingType == 'templateField') {
      this.templatedValue.setValue(mapObj.sourceField);
    } else {
      this.selectedSourceField.setValue(mapObj.sourceField);
    }

    this.selectedAppField.setValue(mapObj.appField);
    this.selectedMappingType.setValue(mapObj.mappingType);
    console.log('this.conditionMap', this.conditionMap);
    this.isEditEnabled = true;

    if (mapObj.sourceField?.referenceField) {
      this.selectedReferenceField.setValue(mapObj.sourceField.referenceField);
      await this.checkForReferenceMapping(mapObj.sourceField);
    }
  }

  showMappingType(mapObj){
    let result = mapObj?.sourceField?.name || "---Field needs to be mapped---";
    if (mapObj.appField.dataType == "array") result = "Items"
    else if(mapObj.mappingType == "templateField") result = "Templated"
    else if (mapObj.mappingType == "conditional") result = "Conditional"
    else if (this.isSmallScreen) result = mapObj?.sourceField?.name;
    return result;
  }

  deleteCondition(i){
    this.conditionMap.conditionSets.splice(i, 1);
  }

  addCondition(){
    this.conditionMap.conditionSets.push({
      conditions : [],
      resultField : "",
      resultType : "text"
    })
  }

  deleteMappingField(index: string) {
    this.allMapping.splice(index, 1);
    this.mappingChange.emit(this.allMapping);
  }

  clearAppField() {
    this.selectedAppField.setValue('');
  }
  clearSourceField() {
    this.selectedSourceField.setValue('');
  }
  clearReferenceField() {
    this.selectedReferenceField.setValue('');
  }
  clearTemplateField() {
    this.templatedValue.setValue('');
  }

  displayFnForAppField(attribute: any) {
    return (attribute && attribute.name) ? attribute.name : '';
  }

  displayFnForSourceField(attribute: any) {
    return attribute && attribute.name ? attribute.name : '';
  }

  filterChanged(e, condition){
    console.log("filterChanged e", e, condition);
    condition.conditions = e.filterItems
  }

  onNewMapping(){
    this.conditionMap = this.defaultConditionMap
  }

  addMappingField() {
    //check for validation
    this.checkForMappingValidation();
    if (!this.isMappingValid) return;

    let mapObj: any = {
      appField: this.selectedAppField.value,
      sourceField: this.sourceFieldValue,
      mappingType: this.selectedMappingType.value
    };

    if (this.sourceFieldValue.reference) {
      mapObj.sourceField.referenceField = this.selectedReferenceField.value
    }

    if(this.selectedMappingType.value == 'conditional'){
      mapObj.conditional = this.conditionMap;
    }
    console.log('MapObj', mapObj);

    let isAdded = true;
    if (this.editMapFieldIndex != null) {
      this.allMapping.splice(this.editMapFieldIndex, 1, mapObj);
      this.editMapFieldIndex = null;
      this.isEditEnabled = false;
      isAdded = false;
    } else {
      this.allMapping.push(mapObj);
    }
    this.clientPlatformService.openSnackBar(`The mapping is ${isAdded ? 'added' : 'updated'}. You can continue adding more.`)

    console.log('Mapping', this.allMapping);

    this.mappingChange.emit(this.allMapping);

    //clearing the slected fields from App & Source.
    this.clearAppField();
    this.clearSourceField();
    this.clearReferenceField();
    if (this.selectedMappingType.value == 'templateField')
      this.clearTemplateField();
  }

  setMappingArr(event: Event, index: number) {
    this.itemsMappingArr[this.selectedAppFieldId][index] = event;
  }

  addItem() {
    if (!this.itemsMappingArr[this.selectedAppFieldId]) {
      this.itemsMappingArr[this.selectedAppFieldId] = []
    }
    this.itemsMappingArr[this.selectedAppFieldId].push([]);
  }

  removeItem(index: number) {
    this.itemsMappingArr[this.selectedAppFieldId].splice(index, 1);
  }

  getItemAppFields() {
    let itemAppFields = [];
    const itemFieldObj = this.appFields.find((field) => field.__id == this.selectedAppFieldId)
    if (itemFieldObj) {
      const fields = itemFieldObj.semanticOptions?.fields;
      for (const field of fields.list) {
        itemAppFields.push(fields[field]);
      }
    }
    return itemAppFields;
  }

  resetMappingSettings() {
    this.clearAppField();
    this.clearSourceField();
    this.clearTemplateField();

    //reset toggles
    this.mappingToggle = !this.mappingToggle;
    if (this.isEditEnabled) this.isEditEnabled = !this.isEditEnabled;
    this.conditionMap = this.defaultConditionMap;
  }

  checkForMappingValidation() {
    if((typeof this.selectedAppField.value !== "object" || typeof this.selectedSourceField.value !== "object") && this.selectedAppField.value.dataType !== 'array') {
      this.isMappingValid = false;
      this.clearAppField();
      this.clearSourceField();
      this.clientPlatformService.openSnackBar('Please choose a valid field!');
    } else if (!this.selectedMappingType.value || !this.selectedAppField.value) {
      this.isMappingValid = false;
      this.clientPlatformService.openSnackBar('Please select an app field.');
    } else if (this.selectedMappingType.value == 'sourceField' && this.selectedAppField.value.dataType !== 'array' && !this.selectedSourceField.value) {
      this.isMappingValid = false;
      this.clientPlatformService.openSnackBar('Please select a source field.');
    } else if (this.selectedMappingType.value == 'templateField' && !this.templatedValue.value) {
      this.isMappingValid = false;
      this.clientPlatformService.openSnackBar('Please provide a value for the template mapping.');
    } else {
      this.isMappingValid = true;
    }

    if (!this.isEditEnabled && this.allMapping.some((mapObj: any) => mapObj.appField.__id == this.selectedAppFieldId)) {
      this.clientPlatformService.openSnackBar(`App field '${this.selectedAppField.value.name}' is already mapped!`);
      this.isMappingValid = false;
    }
  }

  insertCursor(fieldObj: any) {
    let value = fieldObj.__id
    let cursorValue = '${' + value + '}';
    console.log('Cursor value', cursorValue);
    this.templatedValue.setValue(this.templatedValue.value + cursorValue);
  }

  insertCursorDefault(e, conditionObj){
    let value = e.__id
    let cursorValue = '${' + value + '}';
    conditionObj.resultField = cursorValue;
  }

  insertCursorDefaultField(e, conditionObj){
    let value = e.__id
    let cursorValue = '${' + value + '}';
    conditionObj.defaultField    = cursorValue;
  }


  private _filterAttributes(value: any, filterType: string): any {
    let filterValue;
    if (typeof value == 'string') {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.name.toLowerCase();
    }

    if (filterType == 'appField') {
      return this.objectAttributes.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    } else if(filterType == 'referenceField') {
      return this.referenceFields.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    } else {
      return this.sourceFields.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }

  getFilteredObjectAttributes() {
    this.filteredObjectAttributes = this.selectedAppField.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value?.name)),
      map((action) =>
        action
          ? this._filterAttributes(action, 'appField')
          : this.objectAttributes.slice()
      )
    );
  }

  getFilteredSourceAttributes() {
    this.filteredSourceAttributes = this.selectedSourceField.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((action) =>
        action
          ? this._filterAttributes(action, 'sourceField')
          : this.sourceFields?.slice()
      )
    );
  }

  getFilteredReferenceAttributes() {
    this.filteredReferenceAttributes = this.selectedReferenceField.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((action) =>
        action
          ? this._filterAttributes(action, 'referenceField')
          : this.referenceFields.slice()
      )
    );
  }

  async checkForReferenceMapping(value: any) {
    this.referenceFields = [];

    const clientTypeToBoxIdMap = {
      "ZOHOCRM": "zohocrm"
    }

    if (value.reference && !value.referenceField?.hidden) {
      const event = { __id: value.reference };
      const connection = { _id: this.clientPlatformService.workspaceObj?.options?.[`${clientTypeToBoxIdMap[this.clientPlatformService.clientType]}ConnectionId`]}
      this.referenceFields = await this.clientPlatformService.getObjectAttributes(event, connection);
      console.log('REFERENCE fields', this.referenceFields)
    }

    this.getFilteredReferenceAttributes();
  }

  checkForAutoMapping(type?: string) {
    this.autoMappedArr = [];

    for (var i = 0; i < this.objectAttributes.length; i++) {
      const currAttr = this.objectAttributes[i];
      let formattedAppField = currAttr?.name?.replace(/[\-_ ?*]/g, '').toLowerCase();

      for (let j = 0; j < this.sourceFields.length; j++) {
        const currSourceField = this.sourceFields[j];
        let formattedSourceField = currSourceField?.name?.replace(/[\-_ ?*]/g, '')?.toLowerCase();
        // const preDefinedMappingCondition = this.preDefinedMapping && this.preDefinedMapping[currAttr.__id]?.__id == currSourceField.__id;

        if (formattedAppField == formattedSourceField) {
          let autoMapObj = {
            appField: currAttr,
            sourceField: currSourceField,//.name,
            mappingType: this.selectedMappingType.value,
          };
          const isMappingExist = this.allMapping.some(
            (mapObj) => mapObj.appField.name.replace(/[\-_ ?*]/g, '').toLowerCase() == formattedAppField
          );
          // if (preDefinedMappingCondition && isMappingExist) {
          //   this.handlePredefinedMapping(currAttr, autoMapObj);
          // }

          if (!isMappingExist) this.autoMappedArr.push(autoMapObj);
        }
      }
    }

    if (!this.clientPlatformService.isAutoMapping || type == 'manual') {
      console.log('Opening mapping dialog...', this.autoMappedArr);
      let autoMapArrLength = this.autoMappedArr.length;

      if (!autoMapArrLength && type == 'manual') {
        this.clientPlatformService.openSnackBar("Sorry, there is no recommendation of automatic mapping");
      } else if(autoMapArrLength){
        this.openAutoMappingDialog();
      }
    }
  }

  handlePredefinedMapping(currAttr: any, mapObj: any) {
    const currAtrrId = currAttr.__id;
    const index = this.allMapping.findIndex((mObj) => mObj.appField.__id === currAtrrId);

    if (currAttr.dataType == "array" && currAttr.semanticType == "lineItem") {
      if (this.itemsMappingArr[currAtrrId]?.[0]?.length == 0) {
        const lineMapObj = this.preDefinedMapping[currAtrrId]?.lineMapObj || {};
        const itemFields = Object.keys(lineMapObj);
        for (const itemField of itemFields) {
          const { __id, reference, referenceField } = lineMapObj[itemField] || {};
          const sourceField = this.sourceFields.find((sourceField) => sourceField.__id === __id);

          if (sourceField && reference) {
            sourceField.reference = reference;
            sourceField.referenceField = referenceField;
          }

          const itemMapObj = {
            appField: currAttr.semanticOptions.fields[itemField],
            sourceField,
            mappingType: "sourceField"
          }
          this.itemsMappingArr[currAtrrId][0].push(itemMapObj);
        }
      }
      mapObj.sourceField = this.itemsMappingArr[currAtrrId];
    }

    if(index !== -1) this.allMapping[index] = mapObj;
  }

  onSelectionOfAttr(value: any) {
    if (value?.dataType == "array" && value.semanticType === "lineItem") {
      if (!this.itemsMappingArr[value.__id]) {
        this.itemsMappingArr[value.__id] = [[]]
      }
    }
    if (value === "AW_CREATE_NEW_ATTRIBUTE") {
      this.openMappingAddAttrDialog();
      return;
    } else return;
  }

  openMappingAddAttrDialog(): void {

    const dialogRef = this.dialog.open(MappingAddAttributeDialog, {
      data: {
        attribute: {
          name: `Attribute_${this.objectAttributes.length + 1}`,
          dataType: "string",
        },
        options: {
          object: this.appInfo?.object,
          connection: this.appInfo?.connection
        }
      },
    });

    dialogRef.afterClosed().subscribe(async attrResp => {
      console.log('The dialog was closed', attrResp);
      const attrObj = attrResp?.result?.[0];
      if(attrObj) {
        this.selectedAppField.setValue(attrObj);
        this.objectAttributes.push(attrObj);
      };
    });
  }

  openMappingDrillDownDialog(): void {
    const dialogRef = this.dialog.open(MappingDrillDownDialog, {
      width: "500px",
      data: {__id: "", name: "", dataType: "string"},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if(!result?.__id) return;
      result.name = result.__id;
      this.selectedAppField.setValue(result);
      this.objectAttributes.push(result);
    });
  }

  openSourceMappingDrillDownDialog(): void {
    const dialogRef = this.dialog.open(MappingDrillDownDialog, {
      data: {__id: "", name: "", dataType: "string"},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
      if (!result?.__id) return;
      result.name = result.__id;
      this.selectedSourceField.setValue(result);
      this.sourceFields.push(result);
    });
  }
}
